.about_our_service{
    padding:7rem 50px;
    display:flex;
    align-items: center;
    justify-content: center;
    gap:3rem;
}
.about_our_service_content{
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    gap:3rem;
    flex:1;

}
/* service banners */
.service_banner {
    background-size: cover; /* or 'contain' */
    background-repeat: no-repeat; /* Correct property value */
    background-position: center;
    background-image:url('../assets/service_banner.svg') ;
    height:600px;
  }
  .service_res_banner{
    background-image: url("../assets/res_service.svg");
    background-repeat: no-repeat; 
    display: none;
    background-size: cover; 
    height:568px;

  }

  .stru_service_banner {
    background-size: cover; /* or 'contain' */
    background-repeat: no-repeat; /* Correct property value */
    background-position: center;
    background-image:url('../assets/strucbanner.svg') ;
    height:600px;
  }
  .stru_service_res_banner{
    background-image: url("../assets/struRes.svg");
    background-repeat: no-repeat; 
    display: none;
    background-size: cover; 
    height:568px;

  }
  .mep_service_banner {
    background-size: cover; /* or 'contain' */
    background-repeat: no-repeat; /* Correct property value */
    background-position: center;
    background-image:url('../assets/MepBanner.svg') ;
    height:600px;
  }
  .mep_service_res_banner{
    background-image: url("../assets/mepRes.svg");
    background-repeat: no-repeat; 
    display: none;
    background-size: cover; 
    height:568px;


  }
  .sup_service_banner {
    background-size: cover; /* or 'contain' */
    background-repeat: no-repeat; /* Correct property value */
    background-position: center;
    background-image:url('../assets/SupService.svg') ;
    height:600px;
  }
  .sup_service_res_banner{
    background-image: url("../assets/supres.svg");
    background-repeat: no-repeat; 
    display: none;
    background-size: cover; 
    height:568px;


  }
  /* service banners */
  .about_our_service_content>h3{
    text-transform: capitalize;

  }
.about_our_service_content>h3, .what_offer > h3{
    font-size:36px;
    font-family: 'Inter', sans serif;
    line-height: 44px;
    color:#000000;
    font-weight: 600;
    /* color:red */
}
.about_our_service_content >p{
font-weight:400;
font-size:20px;
line-height: 24px;
text-align: justify;
color: #000000;
}
.about_our_service_image{
flex:1;
height:300px;
/* width:600px !important; */
border-radius:10px ;
background-size: cover; 
background-image: url('../assets/Rectangle 14.svg');
}
.about_our_mepservice_image{
    flex:1;
    height:300px;
    /* width:600px !important; */
    border-radius:10px ;
    background-size: cover; 
    background-image: url('../assets/mepService.svg');
}
.about_our_struservice_image{
    flex:1;
    height:300px;
    /* width:600px !important; */
    border-radius:10px ;
    background-size: cover; 
    background-image: url('../assets/struService.svg');
}
.about_our_supservice_image{
    flex:1;
    height:300px;
    /* width:600px !important; */
    border-radius:10px ;
    background-size: cover; 
    background-image: url('../assets/supportService.svg');
}
.about_our_service_image > img{
    border-radius: 10px;
    /* width:100vw; */
    height:300px;
    background-size: cover; 

}
.offers_container_box{
    padding:7rem 6rem;
    display:flex;
    align-items: flex-start;
    flex-direction: column;
    gap:4rem;
    
    background-color: #F7F7F7;
    /* height:1005px; */
}
.what_offer{
    display: flex;
    align-items: center;
    justify-content:center;
    flex-direction: column;
    gap:2rem;
}

.what_offer>p{
    text-align: center;
    /* padding-left: 0.1rem; */
    font-size: 20px;
}
.what_offer>.divider{
    width:166px;
    border:2px solid #714E4E;
}
.offers_container{
    width:100%;
    display: flex;
    gap:1rem;
    align-items: center;
    justify-content: space-between;
}
.offers{
   
    
    margin:3rem 0;
}
.offers_image{
    width:40vw;
    height:306px;
    background: #E7E2E2;
}
.offers_image > img {
width:100%;
height:100%;
border-radius:10px ;

}
.offers_name{
   padding:20px 0px;
   width:600px;
   display: flex;
   align-items: center;
   text-align: justify;
   cursor: pointer;
 
   justify-content: space-between;
}
.about_offers_name {
    transition: all 0.3s ease-in-out;
  }
  
.about_Offers{
    display: flex; 
    width:100% !important;
    justify-content: space-between;
    gap:3rem;
}
#res_offer{
    display: none;
}
.about_offer{
    margin:1rem 0;
    width:584px;
    display: flex;
    text-align: justify;
    font-size: 16px;
}

.icon:focus {
    transform: rotate(180deg); 
  }
.offers_name>.icon{
font-size:28px ;
color:#000000;
transition: transform 0.3s ease-in-out;

}
.offers_name>h3{
    font-size: 28px;
    font-family: 'Roboto',sans-serif;
    font-weight:600;
    line-height:33px;
    text-transform: capitalize;

}

@media (max-width: 560px){
    .about_offer{
        width:80vw;
        margin: 0;
        font-size: 12px;
    }
    .sup_service_res_banner{
        display: block;
    }
    .stru_service_res_banner{
        display: block;
    }
    .mep_service_res_banner{
        display: block;
    }

      .service_res_banner{
        display: block;
      }
    .mep_service_banner{
        display: none;
    }
    .sup_service_banner{
        display: none;
    }
    .stru_service_banner{
        display: none;
    }
    .service_banner {
        display: none;      }
  
    .about_Offers{
        width:100%;
        margin:10px 1rem 1rem 0;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        gap:1rem;
        flex-direction: column;
    }
    .about_our_struservice_image{
        flex:1;
        height:180px;
      
    }
    .about_our_mepservice_image{
        flex:1;
        height:180px;
    }
    .about_our_supservice_image{
        flex:1;
        height:180px;
    }
    .about_our_service_image{
     height:180px;
    }
    .about_our_service{
        padding:5rem 30px;
        display: block !important;
   
    }
    .about_our_service_content{
        gap:1rem;
        margin-bottom:2rem;
    }
   .about_our_service_content>h3, .what_offer > h3{
    font-size:28px;
   }
.about_our_service_content >p{
    font-size: 12px;
}
.what_offer{
    align-items: flex-start;
    gap:16px;
}
.what_offer>p{
    text-align: justify;
    padding: 0;
    font-size: 12px;

}
.what_offer>.divider{
    width:92px;
}
.offers_container_box{
    align-items: flex-start;
    padding:3rem 30px 0;
    height:auto;
    gap:0rem;
}
.offers_container{
display: none;
}
#res_offer{
    width:100%;
    display:initial;
}
.offers_image{
   width:80vw;
   margin-bottom: 10px;
    height:180px;
}
.offers_name{
    padding:0;
    width:80vw;
    gap:1px !important;
    align-items: center !important;
 
 }
.offers_name>h3{
    font-size: 16px !important;
}
.offers_name>.icon{
    font-size: 14px;

}
}
@media (min-width: 560px) and (max-width: 1200px) {
    .about_our_service{
        display: block;

    }
    
    .about_our_struservice_image{
        flex:1;
        height:180px;
      
    } .about_our_service_image{
        height:180px;
       }
  
    .about_our_mepservice_image{
        flex:1;
        height:180px;
    }
    .about_our_supservice_image{
        flex:1;
        height:180px;
    }
    .about_our_service_content{
        margin-bottom: 2rem;
        gap:1rem;
    }
    .offers_container{
        display: none;
        }
        #res_offer{
            width:100%;
            display:initial;
        }
        .offers_image{
            width:70vw;
            margin-bottom:1.4rem;
             height:186px;
         }
         .offers_name{
            padding:0;
            width:70vw;
            gap:1px !important;
            align-items: center !important;
         
         }
         .about_offer{
            width:70vw;
        }
        .offers{
            display: flex;
            flex-direction: column;
            gap:2rem;
        }
        .offers_container_box{
            height:auto;
        }
  }
