
.about_container_box{
    display: flex;
    width:100vw;
    padding:6rem 4rem;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap:3rem;
}
.about_banner {
    background-size: cover; /* or 'contain' */
    background-repeat: no-repeat; /* Correct property value */
    background-position: center;
    position: relative;
    height:600px; /* Centers the image */
    background-image:url('../assets/about_banner.svg') ;
  }
  .about_res_banner{
    background-image: url("../assets/about_res_banner.svg");
    background-repeat: no-repeat; 
    background-size: cover; 
    background-position: center;
    display: none;
    height:580px;
  
  }
.about_container_box>h3, .vision_container_box>.vision>h3,.vision_container_box>.mission>h3{
    font-family: 'Inter',sans-serif;
    font-size:36px;
    line-height: 44px;
    text-align: center;
    color:#000000;
    text-transform: capitalize;
    font-weight:600;
}
.about_container_box>p{
    font-size: 20px;
    font-weight: 400;
    font-family: 'Roboto',sans-serif;
    line-height: 24px;
    text-align: justify;
    color:#000000;
}
.about_container_box>.about_image>.ladder_image{
  width:80vw;

  border-radius:10px;
}
.vision_container{
    width:100vw;
    height:auto;
    background-color: #F7F7F7;
    /* padding:0rem 5rem 0; */
    display: flex;
    align-items: flex-start;
    /* justify-content: center; */

}
.vision_container_box{
    width:90vw;
    display: flex;
    padding:10rem 0;
    align-items: flex-start;
    justify-content: center;

}
.vision_container_box>.vision,.vision_container_box>.mission{
    width:45vw;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap:3rem;
}

.vision_container_box>.vision>p,.vision_container_box>.mission>p{
    width:35vw;
    font-size:20px;
    line-height: 24px;
    text-align: justify;
}
@media (min-width: 560px) and (max-width: 1200px){
    .vision_container_box{
        height:auto;
       align-items: flex-start;
       justify-content:flex-start;
       flex-direction: column;
       gap:3rem;
       padding:5rem 30px;
   
   }
   .vision_container_box>.vision,.vision_container_box>.mission{
    width:90vw;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap:1rem;
}
.vision_container_box>.vision>p,.vision_container_box>.mission>p{
    width:78vw;
    font-size:20px;
    line-height: 24px;
    text-align: justify;
}
}
@media (max-width: 560px){
    .about_container_box>h3, .vision_container_box>.vision>h3,.vision_container_box>.mission>h3{
        font-size:28px;
    }
    .about_container_box{
        padding:5rem 30px;
        align-items: flex-start;
        gap:1rem;
    }
    .about_container_box>p{
font-size:12px;
    }
    .about_banner {
       display:none      }
      .about_res_banner{
       
        display: block;
      
      }
.about_container_box>.about_image>.ladder_image{
  width:85vw;
  border-radius:5px;
}
.vision_container_box{
     height:auto;
    align-items: flex-start;
    justify-content:flex-start;
    flex-direction: column;
    gap:2rem;
    padding:5rem 30px;

}
.vision_container_box>.vision,.vision_container_box>.mission{
    width:80vw;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap:1rem;
}
.vision_container_box>.vision>p,.vision_container_box>.mission>p{
    width:78vw;
    font-size:12px;
    line-height: 24px;
    text-align: justify;
}
}
