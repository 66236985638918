.contact_container_box {
  padding: 5rem 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.map_image {
  width: 100vw;
  height:60vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact_banner {
  background-size: cover; /* or 'contain' */
  background-repeat: no-repeat; /* Correct property value */
  background-position: center;
  height:600px; /* Centers the image */
  background-image:url('../assets/contact_banner.svg') ;
}
.contact_res_banner{
    background-image: url("../assets/contact_res_banner.svg");
    background-repeat: no-repeat; 
    background-size: cover; 
    background-position: center;
  display: none;
  height:568px;


}
.map_image > .location_map {
  border: none;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  position: absolute;

}
.contact_details_container_box_resposive {
  display: none;
}

@media only screen and (max-height: 600px)
{  .contact_details_container_box_resposive {

  gap:5rem;
}
/* .map_image {
  width: 100vw;
  height:40vh;

} */
.con_text{
  font-size:16px;
  }
}
@media (max-width: 560px){
  .con_text{
    font-size:12px !important;
    }
}

@media (min-width: 560px) and (max-width: 1200px) {
  .map_image {
    width: 100vw;
    height:30vh ;
  }
  .contact_details_container_box_resposive {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 90vw;
    gap:5rem;
  }
  .phone_email {
    display: flex;
    gap: 2rem;
    justify-content: space-between;
    width: 100%;
  }
  .contact_details_container_box {
    display: none !important;
  }
  #contact_address {
    width: 80%;
  }
  .contact_details_container {
  
    padding: 5rem 50px;
  }
  .con_text{
    font-size:16px;
    margin-bottom:0 !important;
    
    }
    .contact_container {
   
      width: 50vw !important;
    
    }
}
.contact_container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* flex-direction: column; */
  gap: 2rem;
  width: 100%;

}
.contact_container:hover {
  cursor: pointer;
}
.contact_details_container {
  width: 100vw;
  background-color: #f7f7f7;
  padding: 5rem 50px;
  align-items: center;
  display: flex;
  justify-content: center;
}
.contact_details_container_box {
  display: flex;
  align-items: center;
  gap:7rem;
}
.con_text{
font-size:20px;
}
.contact_icon > h3 {
  font-family: "Inter", sans-serif;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  color: var(--main-color);
  text-transform: capitalize;
  font-weight: 500;
}
.contact_detail > h3 {
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  font-family: "Roboto", sans-serif;
}
.contact_detail {
  flex-direction: column;
  align-items: flex-start;
  width: 22vw;
  height: 10vh;
}
.contact_detail > p {
  font-size: 16px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  line-height: 24px;
  color: #000000;
}
.contact_icon_box {
  width: 48px;
  height: 48px;
  background: var(--main-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}

@media (max-width: 560px) {
  .contact_container_box {
    padding: 3rem 30px;
  }

.contact_banner {
display: none;
}
.contact_res_banner{
  display: block;
}
  .map_image {
    width: 100vw;
    height:20vh ;
  }
  .contact_icon,
  .contact_detail {
    gap: 0.5rem;
  }
  .contact_details_container {
    padding: 4rem 50px;
  }
  .contact_details_container_box {
    flex-direction: column;
    align-items: flex-start;
    gap: 3rem;
    padding: 1rem 0;
  }
  .contact_detail {
    flex-direction: column;
    align-items: flex-start;
    width: 78vw;
    height: 10vh;
  }
  .contact_icon > h3 {
    font-size: 26px;
  }
  .contact_detail > p {
    font-size: 12px;
  }
  .contact_icon_box {
    width: 40px;
    height: 40px;
  }
  .contact_container {
    gap: 1rem;
  }
  .con_text{
    margin-bottom: 0;
  }
}
