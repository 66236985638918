* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  --main-color: #714E4E;
  scrollbar-width: none;

}
h3{
  margin-top:0;
  margin-bottom:0;
}
body{
  font-family: 'Inter', sans-serif;
}
/* banner and header -sec */

.header {
  padding: 10px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
}
.toggle_icon {
  width: 28px;
  height: 28px;
  display: none;
}
.nav_sec {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7rem;
}
@media (max-width: 560px) {
  .toggle_icon {
    display: initial;
  }
  .nav_sec {
    display: none !important;
  }
}
@media (min-width: 560px) and (max-width: 1200px) {


  .toggle_icon {
    display: initial;
  }
  .nav_sec {
    display: none !important;
  }
}
.canvasLink{
  text-decoration: none;
  color:#000;
}
.canvasLink:hover{
  text-decoration: none;

}
.activeLink{
  font-weight: bold;
  color:var(--main-color);
  text-decoration: none;

}
.activeLink:hover{
  text-decoration: none;

}

.nav_sec > .nav_link {
  display: flex;
  gap: 7rem;
  align-items: center;
  justify-content: center;
  padding:0 30px;

}
.links{
  margin-bottom:0;

}

.nav_sec > .nav_link  :hover{
color:#714e4e;
cursor: pointer;
}
.service_container{
  width:267px;
  height:auto;
  border-radius: 1px 1px 0 0;
  position: absolute;
  z-index:1000;
  top:5.2rem;
  right:30rem;
  background: #F7F7F7;
  flex-direction: column;
  padding:12px 10px;
}


.Off_service_container{
  width:267px;
  height:auto;
  border-radius: 1px 1px 0 0;
  display: flex;
  z-index:1000;
  flex-direction: column;
  padding:0px 10px;
  margin-top:-1rem;
  margin-left:1rem;
  margin-bottom:1rem;
}
.Off_service_container > .Off_services{
display:flex;
justify-content: space-between;
padding:10px 10px;
font-size: 14px;
width:230px;
align-items: center;
}
.service_text{
  font-weight: 400;
  font-size:14px !important;
  line-height: 23px;
  text-transform: capitalize;
}
.service_container > .services{
display:flex;
justify-content: space-between;
padding:10px 15px;
align-items: center;
}
.service_container > .services > Link{
  margin-top:0;
}
.service_container > .services >h3{
  font-size: 14px;
  font-weight:500;
  line-height: 16.94px;
  margin-top: 0 ;
  color:#000000;
  font-family: 'Inter',sans-serif;
  text-transform: capitalize;
}
.service_container > .services >h3:hover{
  cursor: pointer;
}

.offcanvas_container{
  padding:2rem 2.5rem;
  display: flex;
  justify-content:flex-start;
}
.offcanvas_lists{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap:1rem;
  padding:20px 0;


}
.offcanvas_lists:hover{
  cursor: pointer;
}
.off_service{
  display: flex;
  align-items: center;
  justify-content: center;
  gap:10px;
}
button,.a_link  {
  width: 140px;
  height: 31px;
  border-radius: 8px;
  border: none;
  text-transform: capitalize;
  color: #fff;
  background-color: var(--main-color);
  text-align: center;
  font-size: 12px;
}
.header-btn{
  width:120px;
}
button,.a_link :hover{
  cursor: pointer;
}
.logo_sec{
  width:120px;

}
.logo_sec > a {
max-width:100%;
}
.banner {
  background-image: url('../assets/home_banner.svg');
  background-size: cover; /* or 'contain' */
  background-repeat: no-repeat; /* Correct property value */
  background-position: center;
  position: relative;
  height:600px;
}

.res_banner{
  background-image: url("../assets/res_banner.svg");
  display: none;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height:568px;
}
.content {
  padding: 7rem 50px;
  display: flex;
  z-index:1000;
  flex-direction: column;
  gap: 3rem;
  align-items: flex-start;
  justify-content: center;
  z-index: 100;
}
.content_text {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.content_text > h3 {
  font-size: 48px;
  width:40vw;
  text-transform: capitalize;
}
.content_text > p {
  font-size: 20px;
  width:40vw;
  text-align: justify;
  line-height: 23.44px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

.a_link{
  display: flex;
  align-items: center;
  text-decoration: none;
  justify-content: center;
}

@media (max-width: 560px){
  .header {
    padding: 10px 30px !important;
  }
  .content_text > h3 {
    font-size: 28px;
  }
  .content_text > p {
    font-size: 12px;

  }
  .res_banner {
    display:block;
  }
  .banner {
    display: none !important;
  }
  .content_text > h3 {
    width:75vw;
  }
  .content_text > p {
    width:70vw;

  }
  .content {
    padding: 5rem 30px;

  }
}
@media (min-width: 560px) and (max-width:1200px){
  .header {
    padding: 10px 30px !important;

  }
  .content {
    padding: 5rem 30px;

  }
  .content_text {
    gap: 2rem;
  }
  .content_text > h3 {
    font-size: 28px;
  }
  .content_text > p {
    font-size: 14px;

  }
  .content_text > h3 {
    width:50vw;
  }
  .content_text > p {
    width:50vw;

  }

  button {
    width: 148px;
    height: 38px;

  }
}
/* banner and header -sec */

/* achivement - container */

.achivement_container {
  padding: 10rem 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.achivement_container > .text {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.achivement_container > .text > h3 {
  font-size: 40px;
  list-style: 48.41px;
  font-weight: 600;
  color: #000000;
}
.achivement_container > .text > p {
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  font-family: "Roboto", sans-serif;
}
.achivement_grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  gap: 3rem;
}
.achivements {
  display: grid;
  grid-template-columns: 7rem auto auto;
  justify-items: start;
}
.achivements .box {
  grid-area: 1/1/3/2;
  width: 60px;
  height: 60px;
  display: flex;
  background-color: var(--main-color);
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}
.achivements > .counts {
  grid-area: 1/2/2/4;
  justify-content: start;
  text-align: left;
  color: #714E4E;
  font-weight: 500;
  font-size: 18px;

}
.achivements > .about {
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  line-height: 17.58px;
}
@media (max-width: 560px){
  .achivements > .counts {
 font-size: 14px;
  }
}
@media (min-width: 560px) and (max-width:1200px){
  .achivement_container {
    padding: 7rem 30px;
    gap:5rem;
    flex-direction: column;
    align-items:flex-start;
  }

  .achivement_container > .text > h3 {
    font-size: 36px;

  }
  .achivements {
    grid-template-columns: 7rem auto auto;
    align-items: center;
  }
  .achivement_container > .text > p {
    font-size:18px;
  }
  .achivements .box {
    width: 60px;
    height: 60px;

  }
  .achivements > .counts {
    grid-area: 1/2/2/4;
    justify-content: start;
    text-align: left;
    margin-bottom: 0;
  }
  .achivements > .about {
    font-size: 16px;
    margin-bottom: 0;

  }
  .achivement_grid {
    gap: 4rem;
  }
}
@media (min-width: 320px) and (max-width: 400px){
  .achivements {
    display: grid;
    grid-template-columns: 4rem auto auto;
    justify-items: start;
  }
  .achivement_container {
    padding: 5rem 0px 5rem 30px ;
    gap:3rem;
    flex-direction: column;
    align-items:flex-start;
  }

  .achivement_container > .text > h3 {
    font-size: 28px !important;

  }
  .achivements {
    grid-template-columns: 5rem auto;
    align-items: center;
    padding:0 30px 0px 0px;
  }
  .achivement_container > .text > p {
    font-size:12px !important;
  }
  .achivements .box {
    width: 45px;
    height: 45px;

  }

  .achivements > .counts {
    grid-area: 1/2/2/4;
    justify-content: start;
    text-align: left;
    margin-bottom:0;
  }
  .achivements > .about {
    font-size: 12px !important;
    margin-bottom:0;
  }
  .achivement_grid {
    gap: 1rem;
    row-gap: 3rem;
  }
}
@media  (min-width: 400px) and (max-width: 560px){
  .achivement_container {
    padding: 7rem 30px  !important;
    gap:3rem;
    flex-direction: column;
    align-items:flex-start;
  }

  .achivement_container > .text > h3 {
    font-size: 28px !important;

  }

  .achivement_container > .text > p {
    font-size:12px !important;
  }
  .achivements {
    display: grid;
    justify-items: start;
    align-items: center;
    grid-template-columns: 4.8rem auto auto;

  }
  .achivements .box {
    width: 40px;
    height: 40px;

  }

  .achivements > .counts {
    grid-area: 1/2/2/4;
    justify-content: start;
    text-align: left;
    margin-bottom: 0;
  }
  .achivements > .about {
    font-size: 12px !important;
    margin-bottom:0;
  }
  .achivement_grid {
    gap: 3rem;
  }
}
@media (max-width: 1200px){
  .achivement_container > .text > h3 {
    width:70vw;

  }

  .achivement_container > .text > p {
    width:70vw;

  }

}
/* achivement_container */

/* core-value */

.core_values_container {
  padding: 5rem 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
  overflow-x: hidden;
  position: relative;

}

.core_values_container > h3 {
  font-size: 36px;
  font-family: "inter", sans-serif;
  font-weight: 600;
  line-height: 43.57px;
  color: #000000;
}

@media (max-width: 560px) {

  .core_values_container {
    align-items: flex-start;
    height: 230px;
    padding:2rem 5rem 30px 30px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .core_values_container > h3 {
    font-size: 28px !important;
  }
  .animation_scroll > div {
    height: 33px !important;
  }
.animation_scroll {
    gap: 3rem !important;

  }

}
.core_values {
  white-space: nowrap;
  width:90vw;
  padding: 10px;
}
.animation_scroll {
  display: flex; /* Enables flex layout */
  gap: 10rem;
}
.animation_scroll > div {
  height: 48px;
  padding: 0 50px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: #463333;
  background-color: #d9d9d9;
  text-transform: capitalize;
  display: flex; /* Ensures content is centered vertically */
  align-items: center; /* Centers content vertically */
  font-family: "Inter", sans-serif; /* Correct font-family */
}
/* core-value */

/* virtual-container */
.virtual_construction_contianer {
  width: 100vw;
  background-color: #f7f7f7;
  padding: 5rem 30px;
}

.virtual_construction_box {
  display: flex;
  flex-direction: column;


  gap: 5rem;
}

.virtual_construction_title {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.virtual_construction_title > h3 {
  font-size: 36px;
  font-weight: 600;
  line-height: 43.57px;
}

.virtual_construction_title > p {
  text-align: center;
  font-size: 16px;
  line-height: 18.75px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  color: #000000;
}

.virtual_construction_cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem;
  /* overflow-x: hidden; */
  scrollbar-width: none;
  /* justify-content: center !important;  */
}


.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  border-radius: 8px;
  /* overflow: hidden; */

}



.card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap:1.5rem;
  align-items: flex-start;
  justify-content: space-between;
  width: 300px;

}

.card-title {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  height:6vh;
  font-size: 20px;
  line-height: 29.05px;
  color: #714e4e;
  text-transform: uppercase;
}

.card-text {
  text-align: justify;
  font-size: 16px;
  line-height: 18.75px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  color: #000000;
  width:300px;
}

.card-img-top {
  width:300px;
  height:200px;
/* max-width: 100%; */
}
.cardImage{
  width:100%;
  height:100%;
 border-radius:10px;
 object-fit: cover;

}
button {
  align-self: flex-start;
}
.readmore-btn{
  width:100px;
}

@media (max-width: 1300px) {
  .virtual_construction_cards {
    display: flex;
    overflow-x: auto;
    gap: 1rem;
  }

  .custom_card {
    flex: 0 0 auto;
    padding:0 !important;

  }
}

@media (min-width: 768px) and (max-width: 930px) {
  .virtual_construction_cards {
    overflow-x: auto;
    gap: 3rem;
  }

  .virtual_construction_contianer {
    padding: 5rem 0px;
  }

  .first_card{
    margin-left:30px ;
  }

  .virtual_construction_box {
    display: flex;
    flex-direction: column;
    padding-left: 0px;
    /* gap: 2rem; */
    margin-right: 0 !important;
  }

  .virtual_construction_title {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    gap: 1rem;
    padding-left: 30px;
  }
  .con_last_card{
    margin-right:20px ;
  }
}

@media (min-width: 768px) and (max-width: 1300px) {
  .virtual_construction_cards {
    overflow-x: auto;
    gap: 3rem;
  }

  .con_last_card{
    margin-right:20px ;
  }

  .virtual_construction_contianer {
    padding: 5rem 0px;
  }

  .first_card{
    margin-left:30px ;
  }

  .card-body > .card-text {
    text-align: justify;
    font-size: 14px !important;
    width: 300px;
    line-height: 18.75px;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    color: #000000;
  }

  .virtual_construction_box {
    display: flex;
    flex-direction: column;
    padding-left: 0px;
    margin-right: 0 !important;
  }

  .virtual_construction_title {
    display: flex;
    flex-direction: column;
    align-items: center !important;
    gap: 1rem;
    padding-left: 30px;
  }
  .virtual_construction_title >p {
    text-align: center !important;
  }
}

@media (max-width: 560px) {
  .first_card{
    margin-left:30px ;
  }

  .card-img-top {
    width:260px;
    height:200px;
  /* max-width: 100%; */
  }
  #last-card{
    margin-right:1rem;
  }
  .virtual_construction_title > h3 {
    font-size: 28px;
  }
  .virtual_construction_contianer {
    padding: 5rem 0px;
  }
  .virtual_construction_title > p {
    font-size: 12px !important;
  }

  .virtual_construction_box {
    display: flex;
    flex-direction: column;
    padding-left: 0px;
    gap: 2rem;
    /* overflow-x: auto */

    padding-right: 0 !important;
  }

  .virtual_construction_cards {
    display: flex;
    overflow-x: auto;
    gap: 3rem;
  }


  .custom_card {
    /* flex: 0 0 auto; */
    padding:0 1rem;
  }

  .card-body > .card-text {
    text-align: justify;
    font-size: 12px !important;
    /* width: 300px; */

    line-height: 18.75px;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    color: #000000;
  }
  .virtual_construction_title {
    align-items: flex-start;
    padding: 0 30px;

  }
  .virtual_construction_title > p {
    text-align: left !important;
    font-size: 12px !important;
  }
  .card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    border-radius: 8px;
    width:260px;
  }

  .card-body{
    width:260px;
  }
  .card-body > .card-text{
    width:250px;
  }
  .card-body > .card-title{
    width:250px;
    font-size:16px;
  }
  .con_last_card{
    margin-right:20px ;
  }
}

@media  (min-width: 560px) and (max-width: 768px) {
  .virtual_construction_title {
    align-items: flex-start;
    padding: 0 30px;
  }

  .virtual_construction_box {
    height: 100% !important;
  }

  .virtual_construction_contianer {
    padding: 5rem 0px;
  }

  .first_card {
    margin-left: 30px;
  }

  .virtual_construction_box {
    display: flex;
    flex-direction: column;
    padding-right: 0 !important;
    padding-left: 0px;
  }

  .virtual_construction_title > p {
    text-align: left !important;
    font-size: 12px !important;
  }
  .card-img-top {
    /* max-width: 100%; */
    border-radius:7px
    }
  .virtual_construction_title > h3 {
    font-size: 28px !important;

  }

  .card-body > .card-title {
    font-size: 20px !important;
  }



  .virtual_construction_cards {
    gap: 2rem;
    display: flex;
    overflow-x: auto;
    /* gap: 1rem; */
  }

  .custom_card {
    flex: 0 0 auto;
  }

  .card-body > .card-text {
    text-align: justify;
    font-size: 14px !important;

    line-height: 18.75px;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    color: #000000;
  }
  .con_last_card{
    margin-right:30px ;
  }
}




/* virtual-container */


/* build_container */

.build_container {
  /* height: 464px; */
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
  background-color: #714e4e;
  padding:5rem 50px;
}
.build_container > .virtual_construction_title > h3 {
  font-size: 36px;
  font-weight: 600 !important;
  font-family: "Inter", sans-serif !important;
  /* margin-top: 4rem; */
}
.build_container > .virtual_construction_title > p {
  color: #fff;
  font-size: 16px;
  font-family: "Roboto", sans-serif !important;
  font-weight: 400 !important;
  line-height: 19px;
}
.build_container > .image {
  width: 100vw;
  /* height: 184px; */
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 560px) {
  .build_container > .virtual_construction_title{
    align-items: center !important;
  }
  .build_container > .image {
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem !important;
  }
  .build_container {
    padding:0 30px !important;
  }

  .build_container > .image > img {
    width: 100% !important;
    height: 100px;
    margin-top: -3rem;
    border-radius: 10px;
  }
  .build_container > .virtual_construction_title > p {
    font-size: 12px !important;
    text-align: center !important;
  }

}
@media (max-width: 767px) {
  .build_container > .virtual_construction_title{
    align-items: center !important;
  }
  .build_container > .image {
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0  !important;
  }
  .build_container {
    padding:3rem 10px 30px !important;
  }

  .build_container > .image > img {
    width: 100% !important;
    height: 100px;
    margin-top: -3rem;
    border-radius: 10px;
  }

  .build_container > .virtual_construction_title > h3{
    font-size:28px;
    text-align: center;
  }
  .build_container > .virtual_construction_title > p {
    font-size: 12px !important;
    text-align: center;
  }
}
.build_container > .image > img {
  max-width: 90vw;
  border-radius: 10px;
}
/* build_container */

/* client - container */

.client_words {
  display: flex;
  flex-direction: column;
  gap:7rem;
  padding: 5rem  50px;

}

.client_words_container > h3 {
  text-align: center;
  font-size: 36px ;
  line-height: 43.57px;
  font-weight: bold;
  font-family: "Inter", sans-serif;
}
.client_container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem;
  align-items: stretch;
  overflow-x: hidden;
  scrollbar-width: none;

}


@media (max-width: 1300px) {
  .client_container {
    display: flex;
    overflow-x: auto;
    gap: 1rem;
  }

  .card {
    flex: 0 0 auto;

  }
}
@media (min-width:768px) and (max-width: 1300px){

.client_words {
  padding: 4rem 0 4rem 0px;
  overflow-x: auto;
  gap:5rem;

}
#card__contain{
  gap:1rem;
    }
.client_words_container > h3{
  text-align: center !important;
  font-size: 28px;
}
.client_first_card{
  padding-left:30px;
}
.client_container {
  display: flex;
  overflow-x: auto;
  gap: 1rem;
}

.card {
  flex: 0 0 auto;
  padding:0 1rem;

}

.last_card{
  margin-right:1.5rem;
}

.client_first_card{
  margin-left:30px;
}
.client_words_container{
  padding-left:30px;
}
  .client_words_container > h3{
    text-align: center;
    font-size: 28px;
  }
  .about_client{
    font-size: 14px !important ;
    font-weight:400px !important;
    color:#000000;
    text-align: justify;
    }
}

@media (min-width:320px) and (max-width:400px){
  .client_first_card{
    margin-left:30px !important;
    /* width:85vw !important; */
  }

  #client-flex {
  width:250px !important;
  }
  .client_words_container > h3{
    text-align: left !important;
    font-size: 28px;
  }
  .last_card{
    margin-right:1.5rem;
  }
  .name_designation > p{
    font-size: 12px  !important;
    margin-bottom:0;
  }
  .name_designation> h5{
    font-size: 14px !important;
    margin-bottom:0;
    height:25px;

  }
  .about_client{
    text-align: justify;
    font-size: 12px !important;
    width: 250px;
    line-height: 18.75px;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    color: #000000;
  }
  .client_container {
    display: flex;
    overflow-x: auto;
    gap: 3rem;
  }

  .card {
    flex: 0 0 auto;
    /* padding: 0  0 1rem 0; */
  }
  .client_words{
    padding-right:  0px;
    padding-left: 0;
    gap:5rem;
  }
  .client_words_container{
    padding-left:30px;
  }
    .client_words_container > h3{
      text-align: center;
      font-size: 28px;
    }
    #card__contain{
      gap:1rem;
    }

}
@media (min-width:400px) and (max-width:500px){

.client_words_container > h3{
  text-align: left !important;
  font-size: 28px;
}

.name_designation> p{
  font-size: 12px  !important;
}
.name_designation> h5{
  font-size: 15px !important;
}
.about_client{
  text-align: justify;
  font-size: 12px !important;
  width: 240px;
  line-height: 18.75px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  color: #000000;
}
.client_container {
  display: flex;
  overflow-x: auto;
  gap: 2rem;
}

.card {
  flex: 0 0 auto;
  /* padding:0 1rem; */

}
.client_words{
  padding-right:  0px;
  padding-left: 0;
  gap:5rem;
  /* background-color: red; */
}
.client_words_container{
  padding-left:30px;
}
  .client_words_container > h3{
    text-align: center;
    font-size: 28px;
  }
  .client_first_card{
    margin-left:30px;
  }
  #client-flex {
    width:240px !important;

  }
  #card__contain{
gap:1rem;
  }

}
@media (min-width:700px) and (max-width: 800px){
  .client_container {
    overflow-x: auto !important;
    gap: 2rem !important;

  }
  .about_client{
    text-align: justify;
    font-size: 14px !important;
    line-height: 18.75px;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    color: #000000;
  }
  .last_card{
    margin-right:1.5rem;
  }
  .client_words{
    padding: 4rem 0 4rem 0px;
    gap:5rem;
  }
  .client_first_card{
    margin-left:30px;
    /* width:280px; */
  }

  .client_words_container{
    padding-left:30px;
  }
    .client_words_container > h3{
      text-align: center;
      font-size: 28px;
    }
    /* .about_client{
      font-size: 14px !important;
    } */
    #client-flex {
      width:300px !important;

    }
    #card__contain{
  gap:1rem;
    }
    .name_designation{
     gap:0.5rem;
     }
}
@media (min-width:500px) and (max-width: 700px){
  .client_container {
    overflow-x: auto !important;

  }
  .last_card{
    margin-right:1.5rem;
  }
.client_first_card{
  margin-left:30px;
  /* width:280px; */
}
.client_words_container{
  padding-left:30px;
}
  .client_words_container > h3{
    text-align: center;
    font-size: 28px;
  }
  #client-flex {
    width:270px !important;

  }
  .name_designation > p{
    font-size: 12px !important; ;
  }
  .name_designation > h5{
    font-size: 16px !important;
  }
 .about_client{
  font-size: 12px !important;
  font-weight:400px !important;
  color:#000000;
  text-align: justify;
  width:270px !important;

 }
.client_words{
  padding: 4rem 0 4rem 0px;
  gap:5rem;
}
#card__contain{
  width:280px !important;
  gap:2rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

}
/*
.clients_grid {
  display: grid;
  grid-template-columns: 7rem auto auto; /* Adjust the first column size */
  /* column-gap: 1rem;
  row-gap:0.4rem;
  align-items: start;
  justify-content: start;
  width: 300px;
} */
/*
.clients_grid img {
  grid-area: 1 / 1 / 3 / 2;
  width:300px;
}
.clients_grid h5,
.clients_grid p {
  margin-bottom: 0;
} */
#card__contain{
  gap:2rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.about_client{
  text-align: justify;
  font-size: 16px;
  line-height: 18.75px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  color: #000000;
 }
 .name_designation{
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  /* width:40vw; */
  margin-top:-3px;
  flex: 1;
 }
 #client-flex{
  display:flex;
  align-items: flex-start !important;
  justify-content: flex-start;
  gap:0.5rem;

  width:300px;
 }
 .client_imgCont > .client_img{
  width:90%;
  height:90%;
  border-radius: 10px;

 }
 .client_imgCont{
  width:100px;
  height:70px;
  flex:0.3;

 }
.name_designation > h5 {
  grid-area: 1/2/2/4;
  font-size: 20px;
  margin: 0 !important;
  text-transform: capitalize;
  font-weight: 500;
  line-height: 30px;
  color: #714e4e;
}
.name_designation> p {
  text-align: justify;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 18.75px;
  color: #6a5b5b;
  margin-bottom: 0;
  /* width:300px; */
}

/* client - container */

.footer-hr {
  width:100%;
}




.responsive-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* height: 100%; */
  overflow: auto;
}

.pdf-document {
  width: 100%;
  display: flex;
  justify-content: center;
}

.pdf-page {
  width: 100%;
  max-width: 100%;
  height: auto;
}

/* @media (max-width: 600px) {
  .responsive-modal {
    padding: 10px;
  }

  .pdf-page {
    width: 100%;
    height: auto;
  }
} */
.page_btns{
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;

}
.page_btns button{
  width:100px;
  border-radius: 5px;
  }
@media (max-width: 600px) {
  .responsive-modal {
    padding: 10px;
  }

  .page_btns button{
    width:70px;
    border-radius: 5px;
    /* padding:0 10px; */
    }
  .pdf-page {
    width: 100%;
    height: auto;
  }

}


.footer {
  height: auto;

  background-color: var(--main-color);
}

.footer_container {
  display: flex;
  flex-direction: column;
  padding: 5rem 50px ;
  color: white;
}
.footer_logo {
  display: flex;

  flex-direction: column;
  gap: 2.5rem;
}
.footer_logo_container {


  display: flex ;
  align-items: center;
  justify-content: space-between;
}
.footer_logo_container >a, .footer_logo_container >img {
  width: 120px;
 height:35px;
}

.footer_logo > p {
  width: 50vw;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 18.75px;
}
.contact_detail_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  margin-top: 2rem;
}
.contact-Container {
  text-align: right;
  display: flex;
  gap:1rem;
  flex-direction: column;

}

.contact-Container > p {
  margin-bottom: 1rem;
}
.contact-Container > a{
  text-decoration: none !important;
  color:#fff;

}
.contact-Container>.address{
  width:20vw;
}
.contact-Container>.address:hover{
  cursor: pointer;
}
.nav-Container {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 equally spaced columns */
  grid-gap: 1.4rem;
}
.nav-Container > a {
  text-decoration: none !important;
  color: #fff;
}
.nav-Container > p {
  /* width:7vw;/ */
  font-size:16px;
}

.nav-Container > p:nth-child(1) {
  grid-column: 1;
  grid-row: 1;
}
.nav-Container > p:hover {
  cursor: pointer;
}
.nav-Container > p:nth-child(2) {
  grid-column: 2;
  grid-row: 1;
  margin-left: -1rem;
}

.nav-Container > p:nth-child(3) {
  grid-column: 3;
  grid-row: 1;
  margin-left: 1rem;


}

.nav-Container > p:nth-child(4) {
  grid-column: 4;
  grid-row: 1;
  margin-left: 1rem;

}

.nav-Container > p:nth-child(5) {
  grid-column: 1 / 3; /* This spans across the first and second columns */
  grid-row: 2;
}

.nav-Container > p:nth-child(6) {
  grid-column: 3 / 5; /* This spans across the third and fourth columns */
  grid-row: 2;
  margin-left: -6rem;

}

.term_details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
}
.terms {
  display:flex;
  gap: 3rem;
  justify-content: center;
  align-items: center;
}
.res_term_details > .terms {
  display:flex;
  gap: 3rem;
  justify-content: center;
  align-items: center;
}
.terms:hover{
  cursor: pointer;
}
.powered_container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.powered_container > p {
  margin-left: 1rem;
}
.powered_container >a, img {
  max-width: 100%;
}
.res_term_details {
  display: none;
}

@media (min-width: 320px) and (max-width: 560px){



  .footer_logo > p {
    width:70vw;
    height:28px;
    font-size: 12px !important;
  }
  .footer_container{
    gap:1rem;
    padding: 5rem 30px ;



  }

  .footer_logo_container {
    /* flex-direction: column; */
    align-items: center;
    justify-content: space-between;
    gap:0rem;
    width:88vw

  }  .footer_logo_container>img{
    /* margin-left: -1.5rem; */
    width:120px !important;
    height:38px;
  }
  .contact-Container > p, .contact-Container > a {
    font-size:12px !important;

  }
  .nav-Container>p{
    font-size:12px !important;
  }
  .links,a{
    font-size: 12px;
  }
  .footer_container {
    align-items: flex-start;
    /* flex-direction: column; */
    padding-bottom: 1rem !important;
  }
  .contact_detail_container {
    flex-direction: column;
    gap: 3rem;
    align-items: flex-start !important;
  }
  .contact-Container {
    text-align: left !important;
  }
  .contact-Container>.address{
    width:70vw;
  }
  .term_details {
    display: none;
  }
  .responsive_terms {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    flex:0.8;
    align-items: flex-start;
  }
  .powered_container {
    /* width: 55px;
    height: 50px; */
    flex:1;
    position: absolute;
    right:2rem;
  }
  .responsive_terms > p {
    font-size: 10px !important;
    margin-bottom:0;
  }
  .terms>p{
    font-size: 10px !important;
    margin-bottom:0;
  }
  .res_term_details {
    display: flex;
    /* margin-top: 1rem; */
    justify-content: space-between;
    align-items: center;
  }
  /* hr {
    z-index: 1;
    width: 100%;
  } */
  .nav-Container {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 4 equally spaced columns */
    align-items: start !important;
    justify-content: start !important;
    row-gap: 1.4rem ;
  }

  .nav-Container > p {
    margin: 0; /* To avoid extra spacing caused by default paragraph margins */
  }

  .nav-Container > p:nth-child(1) {
    grid-column: 1;
    grid-row: 1;
  }
  .nav-Container > p:hover{
    cursor:pointer
  }
  .nav-Container > p:nth-child(2) {
    grid-column: 1;
    grid-row: 2;
    margin-left: 0rem;
  }

  .nav-Container > p:nth-child(3) {
    grid-column: 1;
    grid-row: 3;
    margin-left:0 !important;
  }

  .nav-Container > p:nth-child(4) {
    grid-column: 1;
    grid-row: 4;
    margin-left:0 !important;

  }

  .nav-Container > p:nth-child(5) {
    grid-column: 2 / 3; /* This spans across the first and second columns */
    grid-row: 2;
  }
 .nav-Container > p:nth-child(6) {
    grid-column: 2 / 3;
    grid-row: 2;
    margin-left: 0.01rem !important;
  }


}
@media (min-width: 900px) and (max-width: 1200px){


  .res_term_details {
    display: none;

  }
  .contact_detail_container {
    flex-direction: column;
    gap: 3rem;
    align-items: flex-start !important;
  }
  .contact-Container {
    text-align: left !important;
  }
  .contact-Container>.address{
    width:70vw;
  }
  .responsive_terms {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width:72vw;
  }
.footer_container{
  padding: 5rem 30px ;
  padding-bottom:2rem !important;
}




  .nav-Container {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 4 equally spaced columns */
    align-items: start !important;
    justify-content: start !important;
    row-gap: 1.4rem ;
  }

  .nav-Container > p {
    margin: 0; /* To avoid extra spacing caused by default paragraph margins */
  }

  .nav-Container > p:nth-child(1) {
    grid-column: 1;
    grid-row: 1;
  }
  .nav-Container > p:hover{
    cursor:pointer
  }
  .nav-Container > p:nth-child(2) {
    grid-column: 1;
    grid-row: 2;
    margin-left: 0rem;
  }

  .nav-Container > p:nth-child(3) {
    grid-column: 1;
    grid-row: 3;
    margin-left:0 !important;
  }

  .nav-Container > p:nth-child(4) {
    grid-column: 1;
    grid-row: 4;
    margin-left:0 !important;

  }

  .nav-Container > p:nth-child(5) {
    grid-column: 2 / 3; /* This spans across the first and second columns */
    grid-row: 2;
  }
 .nav-Container > p:nth-child(6) {
    grid-column: 2 / 3;
    grid-row: 2;
    margin-left: 0rem !important;
  }
}
@media (min-width: 560px) and (max-width: 900px){
  .contact_detail_container {
    flex-direction: column;
    gap: 3rem;
    align-items: flex-start !important;
  }
  .nav-Container > p {
    font-size:14px;
  }
  .footer_logo > p {
    width: 70vw;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    line-height: 18.75px;
  }
  .footer_logo_container {
    /* flex-direction: column; */
    align-items: flex-start;
    gap:2.3rem;

  }  .footer_logo_container>img{
    /* margin-left: -1.5rem; */
    width:120px !important;
    height:38px;
  }
  .contact-Container {
    text-align: left !important;
    font-size: 14px;
  }
  .contact-Container>.address{
    width:70vw;
  }
  .responsive_terms {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width:72vw;
  }
.footer_container{
  padding: 5rem 30px ;

  padding-bottom:2rem !important;
}
  .term_details {
    display: none;
  }
  .powered_container {
    width: 100px;
    position: absolute;
    right:2rem;
  }
  .responsive_terms > p {
    font-size: 14px;
  }
  .terms>p{
    font-size: 14px ;

  }
  .res_term_details {
    display: flex;
    margin-top: 1rem;
    align-items: center;
  }
  /* hr {
    z-index: -1;
    width: 100vw;
  } */
  .term_details {
    display: none;
  }
  .responsive_terms {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }
  .powered_container {
    position: absolute;
    right:1rem;
  }
  .responsive_terms > p {
    font-size: 12px !important;
  }
  .terms>p{
    font-size: 12px !important;

  }
  .res_term_details {
    display: flex;
    margin-top: 1rem;
    align-items: center;
  }
  /* hr {
    z-index: -1;
    width: 100%;
  } */
  .nav-Container {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 4 equally spaced columns */
    align-items: start !important;
    justify-content: start !important;
    row-gap: 1.4rem ;
  }

  .nav-Container > p {
    margin: 0; /* To avoid extra spacing caused by default paragraph margins */
  }

  .nav-Container > p:nth-child(1) {
    grid-column: 1;
    grid-row: 1;
  }
  .nav-Container > p:hover{
    cursor:pointer
  }
  .nav-Container > p:nth-child(2) {
    grid-column: 1;
    grid-row: 2;
    margin-left: 0rem;
  }

  .nav-Container > p:nth-child(3) {
    grid-column: 1;
    grid-row: 3;
    margin-left:0 !important;
  }

  .nav-Container > p:nth-child(4) {
    grid-column: 1;
    grid-row: 4;
    margin-left:0 !important;

  }

  .nav-Container > p:nth-child(5) {
    grid-column: 2 / 3; /* This spans across the first and second columns */
    grid-row: 2;
  }
 .nav-Container > p:nth-child(6) {
    grid-column: 2 / 3;
    grid-row: 2;
    margin-left: 0rem !important;
  }
}